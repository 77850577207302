/*! purgecss start ignore */
.p-cover {
  position: fixed;
  width: 100%;
  height: 400px;
  padding-left: 220px;
  background-size: cover;

  @include mq-down(md-lg) {
    height: 300px;
    padding: 0;
    margin-top: 70px;
  }

  @include mq-down(md) {
    height: 200px;
  }

  @include mq-down(sm) {
    height: 150px;
  }

  .s-about-us & {
    background-image: url('../images/cover-about-us.jpg');
  }

  .s-news & {
    background-image: url('../images/cover-news.jpg');
  }

  .s-solution &,
  .s-solution-detail & {
    background-image: url('../images/cover-solution.jpg');
  }

  .s-solution-detail & {
    height: 200px;

    @include mq-down(sm) {
      height: 150px;
    }
  }

  .s-privacy &,
  .s-contact & {
    background-color: $color_red;
  }
}
/*! purgecss end ignore */
