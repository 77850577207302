.p-footer {
  display: flex;
  justify-content: space-between;
  width: 94%;
  max-width: 1440px;
  margin: auto;

  @include mq-down(sm) {
    flex-direction: column-reverse;
  }

  &__copyright {
    @include font-size(12);
  }

  &__link {
    @include font-size(12);

    @include mq-down(sm) {
      margin-bottom: 1rem;
    }

    &:before {
      display: inline-block;
      content: '・';
    }
  }
}
