/*! purgecss start ignore */
.s-privacy .l-article__body,
.s-contact .l-article__body {
  h2 {
    @include font-size(21);
    position: relative;
    display: inline-block;
    padding: 0 5px;
    margin: auto;
    margin-top: 5rem;
    color: $color_red;

    &:after {
      position: absolute;
      bottom: -4px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 2px;
      content: '';
      background-color: $color_red;
    }
  }

  p {
    @include font-size(14);
    margin-top: 1.5rem;
    text-align: center;

    @include mq-down(md-lg) {
      text-align: left;
    }
  }

  strong {
    color: $color_red;
  }

  .wp-block-group + .wp-block-group {
    p {
      margin: 1rem auto 0;
      text-align: center;
    }

    strong {
      @include font-size(24);

      @include mq-down(sm) {
        @include font-size(20);
      }

      a {
        color: $color_red;
      }
    }
  }
}
/*! purgecss end ignore */
