/*! purgecss start ignore */
.s-solution {
  &__card {
    width: calc(100% / 3);
    max-width: 318px;
    margin: 3rem 0 0 1rem;

    @include mq-down(lg) {
      width: calc((100% / 3) - 1rem);

      &:nth-of-type(3n + 1) {
        margin: 3rem 0 0 0;
      }
    }

    @include mq-down(sm) {
      width: 100%;
      max-width: 100%;
      margin: 3rem 0 0 0;
    }

    &:after {
      content: unset !important;
    }

    figure {
      position: relative;
      width: 100%;
      padding-top: 61.8296%;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 0;
        height: 100%;
        content: '';
        background-color: rgba(#fff, 0.4);
        transition: $duration;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;

        @include mq-down(sm) {
          width: 100%;
        }
      }
    }

    &:hover figure:after {
      width: 100%;
    }

    &-caption {
      dt {
        @include font-size(18);
        padding: 0 10px;
        margin: 0.5rem 0;
      }

      dd {
        @include font-size(14);
        padding: 0.5rem 10px 0;
        border-top: 1px solid $color_red;
      }
    }
  }
}
.s-solution-detail .l-article__body {
  padding: 5rem 0 0;
}
/*! purgecss end ignore */
