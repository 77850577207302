.p-header {
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq-down(md-lg) {
      position: relative;
      z-index: 10;
      width: 100%;
      height: 70px;
      line-height: 1;
      background-color: #fff;
      border-bottom: 1px solid $color_border;
    }

    @include mq-up(md-lg) {
      height: 199px;
    }

    img {
      height: 53px;

      @include mq-up(md-lg) {
        height: 53px;
      }
    }
  }

  &__button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 20;
    width: 50px;
    height: 50px;
    border: none;

    span {
      position: absolute;
      top: 12px;
      display: block;
      width: 40px;
      height: 3px;
      background-color: #333;
      transition: $duration;

      .is-opened & {
        background-color: transparent;
        /* transition-delay: 0.22s; */
      }

      &:before,
      &:after {
        position: absolute;
        display: block;
        width: 40px;
        height: 3px;
        content: '';
        background-color: #333;
        transition: $duration;
      }

      &:before {
        top: 10px;

        .is-opened & {
          top: 0;
          transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s,
            transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
          transform: translate3d(0, 10px, 0) rotate(45deg);
        }
      }

      &:after {
        top: 20px;

        .is-opened & {
          top: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
          transform: translate3d(0, 10px, 0) rotate(-45deg);
        }
      }
    }

    @include mq-up(md-lg) {
      display: none;
    }
  }

  &__menu {
    position: absolute;
    top: -300px;
    z-index: 0;
    width: 100%;
    background-color: #fff;
    transition: $duration;

    .is-opened & {
      top: 70px;
    }

    @include mq-up(md-lg) {
      position: unset;
    }

    &-item {
      position: relative;
      z-index: 0;
      border-bottom: 1px solid $color_border;

      &:first-of-type {
        @include mq-up(md-lg) {
          border-top: 1px solid $color_border;
        }
      }

      &:before {
        position: absolute;
        z-index: -1;
        display: inline-block;
        width: 12px;
        height: 100%;
        content: '';
        background-color: $color_red;
        transition: $duration;
      }

      &:hover {
        &:before {
          width: 100%;
        }
      }
    }

    &-item a {
      @include font-size(12);
      display: block;
      padding: 1rem 0;
      padding-left: 12px;
      font-weight: 700;
      line-height: 1;
      text-align: center;

      &:hover {
        color: $color_white;
      }

      &:before {
        @include font-size(20);
        display: block;
        margin-bottom: 5px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        letter-spacing: 5px;
        content: attr(data-text);
      }
    }
  }
}
