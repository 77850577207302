
%font10 {
  @include font-size(10);
}

%font12 {
  @include font-size(12);
}

%font13 {
  @include font-size(13);
}

%font14 {
  @include font-size(14);
}

%font16 {
  @include font-size(16);
}

%font20 {
  @include font-size(20);
}

%font24 {
  @include font-size(24);
}

%font26 {
  @include font-size(26);

  font-weight: 600;
}

%font40 {
  @include font-size(40);
}

%font54 {
  @include font-size(54);
}

%font70 {
  @include font-size(70);
}

// %font58 {
//   font-size: $px58;
//
//   @include mq-down(lg) {
//     font-size: $px48;
//   }
//   @include mq-down(md) {
//     font-size: $px38;
//   }
// }
//
// %font46 {
//   font-size: $px46;
//
//   @include mq-down(lg) {
//     font-size: $px37;
//   }
//   @include mq-down(md) {
//     font-size: $px28;
//   }
// }
//
// %font36 {
//   font-size: $px36;
//
//   @include mq-down(lg) {
//     font-size: $px30;
//   }
//   @include mq-down(md) {
//     font-size: $px24;
//   }
// }
//
// %font30 {
//   font-size: $px30;
//
//   @include mq-down(lg) {
//     font-size: $px26;
//   }
//   @include mq-down(md) {
//     font-size: $px22;
//   }
// }
//
// %font24 {
//   font-size: $px24;
//
//   @include mq-down(lg) {
//     font-size: $px20;
//   }
//   @include mq-down(md) {
//     font-size: $px16;
//   }
// }
//
// %font26 {
//   font-size: $px26;
//
//   @include mq-down(lg) {
//     font-size: $px22;
//   }
//   @include mq-down(md) {
//     font-size: $px18;
//   }
// }
//
// %font20 {
//   font-size: $px20;
//
//   @include mq-down(lg) {
//     font-size: $px18;
//   }
//   @include mq-down(md) {
//     font-size: $px16;
//   }
// }
