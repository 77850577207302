/*! purgecss start ignore */
.s-privacy .l-article__body {
  p {
    text-align: left;

    &:first-child {
      padding-top: 5rem;
      margin-top: 0;
    }
  }

  ul {
    margin-top: 1rem;

    li {
      @include font-size(15);
      position: relative;
      padding-left: 1rem;
      list-style: none;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        color: #e3e3e3;
        content: '●';
      }
    }
  }

  h3 {
    @include font-size(21);
    padding: 0.25rem 0.75rem;
    margin-top: 5rem;
    background-color: #f0f0f0;
    border: 2px solid #e3e3e3;
  }
}
/*! purgecss end ignore */
