/*! purgecss start ignore */

body.s-about-us {
  --wp--style--block-gap: 6rem;
}

.s-about-us {
  &__map {
    height: 400px;
    margin: 2.5rem 0 0;
  }
}

.s-about-us .l-article__body {
  h2 {
    @include font-size(21);
    position: relative;
    display: inline-block;
    padding: 0 5px;
    margin: auto;
    margin-top: 5rem;
    color: $color_red;

    &:after {
      position: absolute;
      bottom: -4px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 2px;
      content: '';
      background-color: $color_red;
    }
  }

  .wp-block-column {
    h3 {
      @include font-size(28);
      position: relative;
      padding: 0.25rem 0 0.25rem 3rem;
      margin: 2.5rem 0 0 -3rem;
      color: #fff;
      background-color: #737373;

      @include mq-down(md) {
        padding: 0.25rem 1rem 0.25rem;
        margin: 2.5rem 0 0;
      }
    }

    p {
      @include font-size(14);
      margin: 1rem 0 0;
    }

    strong {
      @include font-size(16);
    }
  }

  .wp-block-table {
    margin: 2.5rem 0 0;

    td {
      padding: 0.25rem 0.75rem;

      @include mq-up(md) {
        padding: 1.5rem;
      }
    }
  }

  dl {
    margin: 2.5rem 0 0;

    dt {
      @include font-size(16);
      font-weight: 700;
    }

    dd {
      @include font-size(14);
    }
  }

  .wp-block-columns + dl {
    @include mq-up(md) {
      margin: 1.5rem 0 0;
    }
  }
}
/*! purgecss end ignore */
