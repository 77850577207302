.s-news {
  &__lists {
    width: calc(100% - 220px - 4rem);
    text-align: center;

    @include mq-down(md-lg) {
      width: calc(100% - 150px - 2rem);
    }

    @include mq-down(md) {
      width: 100%;
    }
  }

  &__aside {
    @include font-size(14);
    width: 220px;
    margin-top: 2.5rem;

    @include mq-down(md-lg) {
      width: 150px;
    }

    @include mq-down(md) {
      width: 100%;
    }
  }

  &__article {
    padding: 4rem 0 0;
    margin: 4rem 0 0;
    text-align: left;
    border-top: 1px solid $color_border;

    @include mq-down(md) {
      padding: 70px 0;
      margin: 0;
    }

    &:first-of-type {
      // padding: 0;
      border: none;

      h1 + & {
        margin-top: 2.5rem;
      }
    }
  }

  &__time {
    @include font-size(15);
    padding-top: 70px;
    margin-top: -70px;
    font-weight: 700;
  }

  &__title {
    @include font-size(20);
    margin-top: 1rem;
    font-weight: 700;
    color: $color_red;
  }

  &__content {
    @include font-size(14);
    margin-top: 1rem;

    p {
      margin-top: 1rem;
    }
  }

  &__subheadings {
    margin-top: 2.5rem;
    color: $color_red;
  }

  &__aside-list {
    margin-top: 0.5rem;
    list-style: none;
    border-top: 1px solid $color_border;

    li {
      margin-top: 1rem;

      @include mq-down(md) {
        display: inline-block;
        margin-right: 1rem;
      }
    }
  }

  &__lists-headings {
    @include font-size(20);
    position: relative;
    display: inline-block;
    padding: 0 5px;
    margin: auto;
    margin-top: 5rem;
    color: $color_red;

    &:after {
      position: absolute;
      bottom: -4px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 2px;
      content: '';
      background-color: $color_red;
    }
  }
}
