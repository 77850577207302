.l-content {
  position: relative;
  z-index: 100;
  padding: 70px 0 0;

  @include mq-up(md-lg) {
    padding: 0 0 0 220px;
    overflow: hidden;
  }
}
