@keyframes line-scroll {
  0% {
    transform: scaleY(1);
    transform-origin: bottom;
  }

  50% {
    transform: scaleY(0);
    transform-origin: bottom;
  }

  51% {
    transform: scaleY(0);
    transform-origin: top;
  }

  100% {
    transform: scaleY(1);
    transform-origin: top;
  }
}

@keyframes line-link {
  0% {
    transform: scaleX(1);
    transform-origin: right;
  }

  50% {
    transform: scaleX(0);
    transform-origin: right;
  }

  51% {
    transform: scaleX(0);
    transform-origin: left;
  }

  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}
