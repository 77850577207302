/*! purgecss start ignore */
.p-title {
  @include font-size(23);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-shadow: 0 0 10px rgba(#000, 0.5);

  @include mq-down(md-lg) {
    height: 300px;
  }

  @include mq-down(md) {
    @include font-size(20);
    height: 200px;
  }

  @include mq-down(sm) {
    height: 150px;
  }

  &:before {
    @include font-size(40);
    display: block;
    margin-bottom: 8px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 5px;
    content: attr(data-text);

    @include mq-down(md) {
      @include font-size(30);
    }
  }

  .s-solution-detail & {
    height: 200px;
    @include font-size(24);

    &:before {
      content: unset;
    }

    @include mq-down(sm) {
      height: 150px;
    }
  }
}
/*! purgecss end ignore */
