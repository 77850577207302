.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 70px;
  background-color: #fff;

  @include mq-up(md-lg) {
    width: 220px;
    height: 100%;
    border-right: 1px solid $color_border;
    border-bottom: none;
  }
}
