/*! purgecss start ignore */
.l-article {
  background-color: #fff;

  &__body {
    width: 94%;
    /* max-width: 1440px; */
    max-width: 1440px;
    margin: auto;

    .s-news & {
      display: flex;
      justify-content: space-between;

      @include mq-down(md) {
        flex-direction: column;
      }
    }

    .s-solution & {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 2rem;
    }
  }
}
/*! purgecss end ignore */
