/*! purgecss start ignore */
.p-list-information {
  .s-home & {
    @include font-size(15);
    display: inline-flex;
    flex-wrap: wrap;
    width: 80%;
    font-weight: 700;

    @include mq-down(md) {
      width: 100%;
      margin-top: 1rem;
    }

    dt {
      width: 7rem;
      margin-top: 0.5rem;

      &:first-of-type {
        margin-top: 0;
      }
    }

    dd {
      width: calc(100% - 7rem);
      margin-top: 0.5rem;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}
/*! purgecss end ignore */
